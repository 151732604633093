import { Outlet } from "react-router"
import { SidebarProvider } from "~/components/ui/sidebar"
import { AppSidebar } from "./app-sidebar"

export default function AppLayout() {
  return (
    <SidebarProvider>
      <AppSidebar />
      <main className="w-full h-dvh bg-muted p-2">
        <Outlet />
      </main>
    </SidebarProvider>
  )
}
