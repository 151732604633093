import { type NavLinkProps, NavLink as RRNavLink } from "react-router"
import { cn } from "~/lib/utils"

export function NavLink(props: NavLinkProps) {
  return (
    <RRNavLink
      {...props}
      className={({ isActive }) =>
        cn({ "font-bold bg-background shadow": isActive }, props.className)
      }
    />
  )
}
