import {
  GitBranchPlus,
  MessageSquarePlus,
  MessagesSquare,
  Upload,
  Workflow,
} from "lucide-react"
import { Link } from "react-router"
import { Logo } from "~/components/logo"
import { NavLink } from "~/components/nav-link"

import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "~/components/ui/sidebar"

const items = [
  {
    title: "New chat",
    to: "chat",
    icon: MessageSquarePlus,
  },
  {
    title: "New flow",
    to: "flow",
    icon: GitBranchPlus,
  },

  {
    title: "Flows",
    to: "flows",
    icon: Workflow,
  },
  {
    title: "Chats",
    to: "chats",
    icon: MessagesSquare,
  },
  {
    title: "Uploads",
    to: "uploads",
    icon: Upload,
  },
]

export function AppSidebar() {
  return (
    <Sidebar collapsible="icon" className="bg-muted border-none">
      <SidebarContent className="drop-shadow-none">
        <SidebarHeader className="px-4 pb-0">
          <Link to="/app">
            <Logo />
          </Link>
        </SidebarHeader>
        <SidebarGroup className="pt-0">
          <SidebarGroupContent>
            <SidebarMenu>
              {items.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild>
                    <NavLink to={item.to}>
                      {item.icon && <item.icon />}
                      <span>{item.title}</span>
                    </NavLink>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  )
}
